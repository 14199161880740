import {
  Experiment,
  ExperimentClient,
  ExperimentUser,
  Variant,
} from '@amplitude/experiment-js-client'
import { isClient, isServer } from '../utils/runtimeUtils'
import { experimentManager } from 'services/ExperimentManager/ExperimentManager'
import { useMemo } from 'react'
import { LANGUAGE_LOCALES, LANGUAGES } from '../locale/Translation.consts'
import cc from '../locale/custom/countries.json'

export function getExperimentsUser(
  userId: string,
  language: string,
  country: string
): ExperimentUser {
  return {
    user_id: userId,
    language: LANGUAGE_LOCALES[language as LANGUAGES],
    country: cc.en[country as keyof typeof cc.en],
  }
}

export function useInitExperimentClient({
  features,
  keys,
  userId,
  language,
}: {
  features: Record<string, Variant>
  keys: Record<string, any>
  userId: string
  language: string
}) {
  const key = keys.amplitudeDeploymentKey
  let client: ExperimentClient = useMemo(() => {
    const userProvider = {
      getUser: () => getExperimentsUser(userId, language, keys.ipCountry),
    }
    if (isServer()) {
      return new ExperimentClient(key, {
        fetchTimeoutMillis: 1000,
        pollOnStart: false,
        initialVariants: features,
        userProvider,
      })
    }

    const client = Experiment.initializeWithAmplitudeAnalytics(key, {
      fetchTimeoutMillis: 1000,
      pollOnStart: false,
      initialVariants: features,
      userProvider,
    })
    // Calling start so the experiments will be saved in the local storage
    client.start(userProvider.getUser())
    return client
  }, [])

  if (isClient()) {
    experimentManager.updateClient(client)
  }

  return client
}
