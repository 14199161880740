import React, { forwardRef, useState } from 'react'
import * as S from './CommonSnackbar.styles'
import useElementIntersection from '../../../hooks/useElementIntersection'
import useOnMount from '../../../hooks/useOnMount'
import SwipeableSnackbar from '../SwipeableSnackbar/SwipeableSnackbar'
import { translateManager as t } from '../../../services/TranslateManager'
import { CustomContentProps, useSnackbar } from 'notistack'
import { analytics, EVENT_NAMES } from '../../../services/Analytics/Analytics'
import { CommonSnackbarExtraOptions } from '../types'
import { ICON_SIZES } from '../Snackbar.consts'

const CommonSnackbar = forwardRef<
  HTMLDivElement,
  CommonSnackbarExtraOptions & CustomContentProps
>((props, ref) => {
  const {
    title,
    subtitle = '',
    action = '',
    showArrow = false,
    icon,
    iconSize = ICON_SIZES.SMALL,
    actionCallback = () => {},
    name,
    analyticsProperties,
    id,
  } = props

  const { elementRef, intersectionRatio } =
    useElementIntersection<HTMLDivElement>({
      rootMargin: '-10px 0px 0px 0px',
    })
  const [backgroundWidth, setBackgroundWidth] = useState('80%')

  const { closeSnackbar } = useSnackbar()

  useOnMount(() => {
    analytics.track(EVENT_NAMES.TOAST_DISPLAYED, {
      Name: name,
      ...analyticsProperties,
    })
    setBackgroundWidth('100%')
  })

  const minimizeThresholdLength = 59
  const shouldMinimize = title.length > minimizeThresholdLength

  const currentLanguage = t.getLanguage()

  const hasBigIcon = iconSize === ICON_SIZES.BIG

  const text = subtitle ? `${title}; ${subtitle}` : title

  const onClose = () => {
    analytics.track(EVENT_NAMES.TOAST_DISMISSED, {
      Name: name,
      Text: text,
      Method: 'X Button',
      ...analyticsProperties,
    })
    closeSnackbar()
  }

  const handleActionTap = () => {
    actionCallback()
    analytics.track(EVENT_NAMES.TOAST_ACTION_TAPPED, {
      Name: name,
      Text: text,
      ...analyticsProperties,
    })
  }

  return (
    <SwipeableSnackbar name={name} text={text} id={id} ref={ref}>
      <S.Content $showIllustration={!!icon} ref={elementRef}>
        <S.TextWrapper $hasBigIcon={hasBigIcon}>
          <S.LeftWrapper $hasBigIcon={hasBigIcon}>
            {icon && (
              <S.IconWrapper $hasBigIcon={hasBigIcon}>{icon}</S.IconWrapper>
            )}
            <S.TitlesWrapper>
              <S.Title
                $showAction={!!action}
                $shouldMinimize={shouldMinimize}
                $language={currentLanguage}
                $hasBigIcon={hasBigIcon}
                data-testid="snackbar-title"
              >
                {title}
              </S.Title>
              {subtitle && (
                <S.SubtitleWrapper>
                  {showArrow && <S.Arrow />}
                  <S.Subtitle
                    data-testid="snackbar-subtitle"
                    $hasBigIcon={hasBigIcon}
                  >
                    {subtitle}
                  </S.Subtitle>
                </S.SubtitleWrapper>
              )}
            </S.TitlesWrapper>
          </S.LeftWrapper>
          {!!action && (
            <S.Action onClick={handleActionTap} $language={currentLanguage}>
              {action}
            </S.Action>
          )}
        </S.TextWrapper>
        <S.XIconWrapper $hasBigIcon={hasBigIcon}>
          <S.ThinXIcon $hasBigIcon={hasBigIcon} onClick={onClose} />
        </S.XIconWrapper>
        <S.Background
          hasShadow={intersectionRatio === 1}
          width={backgroundWidth}
        />
      </S.Content>
    </SwipeableSnackbar>
  )
})

export default CommonSnackbar
